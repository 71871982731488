import { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import { collection, getDocs, getFirestore, onSnapshot, query } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const App = () => {

  const [cmds, setCmds] = useState([])

  useEffect(() => {
    const getData = async () => {
      const firebaseConfig = {
          apiKey: "AIzaSyCYUkX1yJkHfIMWUBzS35vQ63j_Q4m0a3Q",
          authDomain: "midiohalles-3a2e5.firebaseapp.com",
          projectId: "midiohalles-3a2e5",
          storageBucket: "midiohalles-3a2e5.appspot.com",
          messagingSenderId: "912554267233",
          appId: "1:912554267233:web:2939ad249d6e4b41419020"
      };
    
      const app = initializeApp(firebaseConfig);
    
      const db = getFirestore(app);

      const q = query(collection(db, "commandes"));

      onSnapshot(q, (querySnapshot) => {
        const commandes = [];
        querySnapshot.forEach((doc) => {
          commandes.push(doc.data());
        })
        console.log(commandes);
        commandes.sort(function(a, b) {
          var keyA = new Date(a.date),
            keyB = new Date(b.date);
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        setCmds(commandes);
      })
    }

    getData();
  }, []);

  useEffect(() => {
    console.log("z", cmds);
  }, [cmds])

  return (
    <View style={{ height: "100vh", width: "100vw" }}>
      <Text style={{ fontSize: 40, fontWeight: "600", textAlign: "center", marginTop: 50, height: 50 }} >Click and collect admin</Text>
      <ScrollView style={{width: "100vw", height: "calc(100vh - 200px)", marginVertical: 50, paddingTop: 10}} >
        {
          cmds.map((el, i) => {

            const date = new Date(el.date);
            const datevalues = [
              date.getFullYear(),
              date.getMonth()+1,
              date.getDate(),
              date.getHours(),
              date.getMinutes(),
              date.getSeconds(),
           ];

            return (
              <View key={i} 
                style={{
                  alignSelf: "center", 
                  width: "50vw", 
                  minHeight: 100, 
                  borderRadius: 10, 
                  padding: 10, 
                  marginBottom: 20, 
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 0,
                  },
                  shadowOpacity: 0.4,
                  shadowRadius: 5,
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <View>
                  <Text>{datevalues[2]}/{datevalues[1]}/{datevalues[0]} à {datevalues[3] <= 9 ? "0" + datevalues[3] : datevalues[3]}h{datevalues[4] <= 9 ? "0" + datevalues[4] : datevalues[4]}</Text>
                  <Text style={{ marginVertical: 10 }}>Client: {el.nom}</Text>
                  <Text>{el.plats.length} plats à préparer :</Text>
                  {
                    el.plats.map((el2, j) => {
                      return (
                        <View key={el2}>
                          <Text>- {el2.quantity} x {el2.name}</Text>
                        </View>
                      )
                    })
                  }
                  <Text style={{ marginTop: 10 }}>Prix total : {el.prix} €</Text>
                </View>
                <View>
                  <Text>Status</Text>
                  <Text>Commande prête : {el.finie ? "oui" : "non"}</Text>
                  <Text>Commande payée : {el.paid ? "oui" : "non"}</Text>
                </View>
              </View>
            )
          })
        }
      </ScrollView>
      
    </View>
  )
}

export default App;